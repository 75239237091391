<template>
  <div class="upload">
    <el-upload
      class="uploadvideo"
      action=""
      :accept="allowFileType"
      :auto-upload="false"
      :show-file-list="false"
      :on-change="handleChange"
      :on-exceed="onExceed"
      :disabled="disabled"
    >
      <!-- <el-button size="mini" style="font-size:12px;padding: 10px" v-if="text != '批量上传'" slot="trigger">批量上传视频</el-button> -->
      <el-button type="text" style="font-size:12px;padding:0; color: #606266;" :disabled="disabled">上传视频</el-button>
      <!-- <span style="font-size:12px;" class="spanbutton">批量上传视频</span> -->
    </el-upload>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //配置参数
      timeout: "6000000",
      partSize: "1048576",
      parallel: "5",
      retryCount: "3",
      retryDuration: "2",
      region: "cn-shanghai",
      userId: "1192632080203852",
      //上传对象
      fileList: [],
      allowFileType: ".mp4,.MP4",
      //组件对象
      ossUploader: null,
      uploading: false,
      uploadingVideoId: ''
    };
  },
  props:['text','disabled'],
  created() {
    this.ossUploader = this.createUploader();
  },
  methods: {
    checkReg(val, regName) {
      let reg = {
        userName: /^[a-zA-Z0-9_]{2,16}$/,
        password: /^[A-Za-z0-9]+$/,
        fullName: /^[\u4e00-\u9fa5]+$/,
        caseAndNumber: /^[A-Za-z0-9]+$/,
        code: /^[A-Za-z0-9_-]+$/,
        phone: /^((0\d{2,3}-\d{7,8})|(0\d{9,11})|(1\d{10})|(\d{7,8}))$/,
        mobile: /^1\d{10}$/,
        fileName: new RegExp('[\\\\/:*?"<>|]'),
        eMall: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      };
      return reg[regName].test(val);
    },
    // 文件基本信息校验
    fileChecker(file) {
      let that = this;
      //文件名不能超长,不能还有特殊字符
      let fileName = file.name;
      if (fileName.length > 10000000) {
        that.$message.warning({
          message: "资源[" + fileName + "]名称不能大于10MB！",
          duration: 1000
        });
        return false;
      }
      if (that.checkReg(fileName, "fileName")) {
        that.$message.warning({
          message: "资源文件[" + fileName + "]名称不能包含特殊字符！",
          duration: 1000
        });
        return false;
      }
      //校验文件类型，是否允许上传
      let fileType = file.name.split(".");
      if (!that.allowFileType.includes(fileType[fileType.length - 1])) {
        that.$message.warning({
          message: "只能上传.MP4格式的文件！",
          duration: 1000
        });
        return false;
      }
      return true;
    },
    matchFile(fileRaw) {
      return this.fileList.find(file => {
        return file.uid == fileRaw.uid;
      });
    },
    /**
     * 文件列表改变事件,批量上传组件中,该事件只监听新增文件
     * @param file
     */
    handleChange(file) {
      if (this.fileChecker(file)) {
        let info = {
          title: file.name,
          filename: file.name
        };
        this.$post("/sys/video/createUploadVideo", info)
          .then(result => {
            if (result.status === "0") {
              this.$set(file, 'catalogName', file.name.substring(0, file.name.lastIndexOf('.')));
              this.$set(file, 'uploadAuth', result.data.uploadAuth);
              this.$set(file, 'uploadAddress', result.data.uploadAddress);
              this.$set(file, 'videoId', result.data.videoId);
              this.$set(file, 'kpointLessonNum', 1);
              this.ossUploader.addFile(file.raw, null, null, null, '{"Vod":{}}');
              
              this.fileList.push(file);
              this.$emit('onAddFile', file);
              //如果没有处于上传状态则开启上传状态
              if (!this.uploading) {
                this.uploading = true;
                this.ossUploader.startUpload();
              }
            } else {
              self.$message.warning({ message: result.msg, duration: 1500 });
            }
          })
          .catch(err => {
            this.$message.error(err.message);
          });
      }
    },
    /**
     * 构建oss上传对象
     * @param
     */
    createUploader() {
      let self = this;
      let uploader = new AliyunUpload.Vod({
        timeout: self.timeout,
        partSize: self.partSize,
        parallel: self.parallel,
        retryCount: self.retryCount,
        retryDuration: self.retryDuration,
        region: self.region,
        userId: self.userId,
        // 开始上传
        onUploadstarted(uploadInfo) {
          let target = self.matchFile(uploadInfo.file);
          self.uploadingVideoId = target.videoId;
          uploader.setUploadAuthAndAddress(uploadInfo, target.uploadAuth, target.uploadAddress, target.videoId);
          self.$emit('fileUploadStart',target.videoId);
        },
        //文件上传成功
        onUploadSucceed(uploadInfo) {
          let target = self.matchFile(uploadInfo.file);
          if (target) {
            self.$emit('fileUploadSuccess2', target.videoId, target);
          }
        },
        onUploadCanceled() {

        },
        // 文件上传失败
        onUploadFailed(uploadInfo, code, message) {
          self.$message.error(uploadInfo.file.name + ' 上传失败');
          console.error(uploadInfo, code, message, 'onUploadFailed');
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress(uploadInfo, totalSize, progress) {
          let target = self.matchFile(uploadInfo.file);
          if (target) {
            self.$emit('fileUploadProgress2', progress, target.videoId);
          }
        },
        // 上传凭证超时
        onUploadTokenExpired(uploadInfo) {
          // 上传大文件超时, 如果是上传方式一即根据 UploadAuth 上传时
          // 需要根据 uploadInfo.videoId 调用刷新视频上传凭证接口(https://help.aliyun.com/document_detail/55408.html)重新获取 UploadAuth
          // 然后调用 resumeUploadWithAuth 方法, 这里是测试接口, 所以我直接获取了 UploadAuth
          let refreshUrl =
            "https://demo-vod.cn-shanghai.aliyuncs.com/voddemo/RefreshUploadVideo?BusinessType=vodai&TerminalType=pc&DeviceModel=iPhone9,2&UUID=59ECA-4193-4695-94DD-7E1247288&AppVersion=1.0.0&Title=haha1&FileName=xxx.mp4&VideoId=" +
            uploadInfo.videoId;
          self.$get(refreshUrl).then(({ data }) => {
            let uploadAuth = data.UploadAuth;
            uploader.resumeUploadWithAuth(uploadAuth);
            console.warn("upload expired and resume upload with uploadauth " + uploadAuth);
          });
        },
        // 全部文件上传结束
        onUploadEnd() {
          self.uploading = false;
        }
      });
      return uploader;
    },
    onExceed(files, fileList) {
      this.$message.error('单次上传最大只能选择5个');
    },
    remFile(file) {
      // 在上传中要先暂停
      if (this.uploading) {
        this.ossUploader.stopUpload();
      }
      let ossFiles = this.ossUploader.listFiles();
      let index = -1;
      // 找到oss上传列表中对应的文件项,如果该项在上传中需要先取消
      for (let i = 0; i < ossFiles.length; i++) {
        if (file.videoId == ossFiles[i].videoId) {
          index = i;
          if (this.uploadingVideoId == ossFiles[i].videoId) {
            this.ossUploader.cancelFile(i);
          }
          break;
        }
      }
      // 同时删除oss 文件列表和本地文件缓存列表
      if (index > -1) {
        this.ossUploader.deleteFile(index);
        this.fileList.splice(index, 1);
      }
      // 重新开始上传
      this.ossUploader.startUpload();
    }
  }
};
</script>
<style lang="less">
.upload {
  .uploadvideo {
    display: flex;
    .el-upload {
      height: auto !important;
      border: none;
    }
  }
  .el-button {
    height: auto !important;
  }
}
.spanbutton {
   color: #606266;
}
.spanbutton:hover{
    color: #409EFF;
    border-color: #c6e2ff;
    background-color: #ecf5ff;
}
</style>
